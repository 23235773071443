import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { FaRegCommentDots, FaDownload, FaArrowLeft } from 'react-icons/fa';
import './Work.scss';

const API = "/works/";

class Work extends Component {

  constructor(props) {
    super(props);

    this.getHtml = this.getHtml.bind(this)

    this.state = {
      html: "default",
      isLoading: false,
      hasLoaded: false
    };
  }

  componentDidMount() {

    console.log('did');

    if(!this.state.hasLoaded && this.props.works.length) {
      this.getHtml(this.props)
    }

  }

  componentWillUpdate(nextProps) {

    console.log('will');

    if(!this.state.hasLoaded) {
      this.getHtml(nextProps)
    }

  }

  getHtml(props) {

    let work = props.works.find(work => work.path === this.props.path);

    // get HTML
    fetch(API + work.path + "/" + work.html)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Something went wrong loading the text');
        }
      })
      .then(html => {
        this.setState({ html: html, isLoading: false, hasLoaded: true })
      })
      .catch(error => this.setState({ error, isLoading: false }));

  }

  render() {

    if(!this.props.work) {
      return 'hang on...';
    }

    return (
      <div className="WorkWrapper">

        <div className="WorkHeader">
          <Link to="/" className="WorkBack"><FaArrowLeft/> Index</Link>
          <div className="wordCount">({this.props.work.wordCount} words)</div>
          <div className="WorkButtons">
            <a href={"/works/" + this.props.work.path + "/" + this.props.work.pdf } target="_blank" rel="noopener noreferrer" title="download a PDF"><FaDownload/></a>
            <a href={"mailto:greg@gregsimsic.com?subject=Re:" + this.props.work.title} target="_blank" rel="noopener noreferrer" title="email me"><FaRegCommentDots/></a>
          </div>
        </div>

        <div className="Work">

          <div dangerouslySetInnerHTML={{ __html: this.state.html }} />

          <div className="WorkFooter">
            <a href={"mailto:greg@gregsimsic.com?subject=Re:" + this.props.work.title} title="email me" target="_blank" rel="noopener noreferrer" className="FooterComment">Thoughts?</a>
          </div>

        </div>


      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    works: state.items,
    work: state.items.find(work => work.path === ownProps.path)
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(Work);