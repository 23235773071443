import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import './App.scss';
import { connect } from 'react-redux';

import Index from 'components/index/Index';
import Work from 'components/work/Work';

import { itemsFetchData } from '../../actions/items';

class App extends Component {

  componentDidMount() {
    this.props.fetchData('/works.json');
  }

  render() {
    return (
      <div className="App">

        <Router>
          <div className="App-inner">

            <Route render={({ location, history }) => (

              <div className="stable">
                <Switch key={location.key} location={location}>

                  <Route exact path="/" render={(props) =>
                    <Index/>
                  }
                  />

                  <Route exact path={"/work/:path"} render={(props) =>
                    <Work
                      path={props.match.params.path}
                    />
                  }
                  />

                  {/* handle 404s */}
                  <Route path="*" render={() =>
                    <Redirect to="/"/>
                  }
                  />
                </Switch>
              </div>

            )} />

          </div>
        </Router>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    works: state.items,
    hasErrored: state.itemsHasErrored,
    isLoading: state.itemsIsLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (url) => dispatch(itemsFetchData(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
