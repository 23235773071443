import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import './Index.scss';

class Index extends Component {

  render() {
    return (
      <div className="Index">

        <ul>

        {this.props.works.map( (work, i) =>

          <li key={i}>
            <Link to={"/work/" + work.path }>{work.title}</Link>
            <br/>
            {/*<Link to={"/work/" + work.path }>read</Link>*/}
            <a href={"/works/" + work.path + "/" + work.pdf } className="download" target="_blank" rel="noopener noreferrer">or download PDF</a>
            <br/>
            <span className="wordCount">({work.wordCount + " words"})</span>
          </li>

        )}

        </ul>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    works: state.items.filter(work => work.inIndex)
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(Index);
